<template lang="pug">

div
  EmbedCe(v-if='taskAttribute.BasketID', :url='ceUrl')
  
</template>
  
<script>

  export default {
    data () {
      return {
        basketId: []
      }
    },
    components: {
      EmbedCe: () => import('@/components/EmbedCe.vue'),
      ApiLookup: () => import('@/components/ApiLookup.vue'),
    },
    inject: [
      'reloadTask'
    ],
    props: {
      taskAttribute: Object,
    },
    watch: {
    },
    methods: {

    },
    computed: {
      ceUrl () {
        return `?search=1&BasketID=${this.taskAttribute.BasketID}`
      }
    },
    mounted () {

    }
  }

</script>